html {
	background-color: #222;

	.App {
		background-color:#444;
		color: #f9f9f9;
		header {
			.badge {
				margin-left: -5px;
			    margin-top: -5px;
			    position: absolute;

				&.badge-danger {
					background-color: rgba(220, 53, 69, 0.7);
				}
			}
		}

		footer {
			background-color: #222;
			color:#cacaca;
		}

		.mb-2 {
			margin-bottom:2em;
		}

		.mt-2 {
			margin-top:2em;
		}

		.pt-4 {
			padding-top:4em !important;
		}

		a {
			color:#aaeeff;
		}

		/* Notifs */
		.alert-dark {
		    color: #dee9f3;
		    background-color: #335069;
		    border-color: #405f7d;
		}

		/* Products */
		.product {
			.price {
				color: #c9c9c9;
			}

			.form-control,  .form-control:focus, .form-control:active {
				background-color: #222;
				border-color: #c9c9c9;
				color: #c9c9c9;
				text-align:center;
				font-weight: bold;
			}
		}

		/* Cart */
		.cart {
			.media {
			    background-color: #343a40;
			    padding: 1em;

				.form-control,  .form-control:focus, .form-control:active {
					background-color: #222;
					border-color: #c9c9c9;
					color: #c9c9c9;
					text-align:center;
					font-weight: bold;
				}
			}
		}

	}
}

.swal2-popup {
	background-color:#31414e !important;
	.swal2-title {
		color: #fff;
	}
	.swal2-styled.swal2-confirm {
		background-color: #c42727;
	}
}
